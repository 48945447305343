@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.card {
  @apply bg-[#000] bg-opacity-50 rounded-lg border border-opacity-35 max-lg:bg-opacity-100 max-lg:bg-gray-dark border-gray-light;
}

.title {
  @apply text-4xl lg:text-5xl font-bold leading-[1.1];
}

.title-text {
  @apply text-2xl lg:text-5xl font-bold;
}

.description {
  @apply text-lg lg:text-xl font-normal;
}
